import { combineReducers, createStore } from "redux";
import selectedFilterDataReducer from "./reducers/selectedFilterDataReducer";
import loadingReducer from "./reducers/loadingReducer";
import mainDataReducer from "./reducers/mainDataReducer";
import popupReducer from "./reducers/popupReducer";
import searchResultReducer from "./reducers/searchResultReducer";
import massMessageReducer from "./reducers/massMessageReducer";

export const store = createStore(
  combineReducers({
    selectedFiltersData: selectedFilterDataReducer,
    searchResultData: searchResultReducer,
    loadingData: loadingReducer,
    popupData: popupReducer,
    mainData: mainDataReducer,
    massMessageData: massMessageReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
