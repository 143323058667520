export const generateSessionId = () => {
  const newSessionID = String(Math.floor(Math.random() * 10000));
  return localStorage.setItem("sessionId", newSessionID);
};

export const getAuthDataStorage = () => {
  const data = localStorage.getItem("auth_data");
  return data ? JSON.parse(data) : null;
};

export const isWithUserId = () => {
  const { pathname } = window.location;
  return pathname.split("/")[1] === "user_id";
};

export const getUrlFilterId = () => {
  const { pathname } = window.location;
  const result = pathname.split("/")[1];

  return !isWithUserId() && result;
};

export const getUrlUserrId = () => {
  const { pathname } = window.location;
  const result = pathname.split("/")[2];

  return result;
};

export const getArrayValue = (array, index) => {
  return +array[index];
};

export const getIndex = (array, value) => {
  return array.indexOf(value) + 1;
};

export const getIndexes = (array, values) => {
  if (Array.isArray(values)) {
    const indexes =
      values?.length && array?.length
        ? values?.map((item) => array.indexOf(item) + 1)
        : [0, 1];

    if (indexes.length === 1) {
      indexes.unshift(0);
    }

    return indexes;
  }
  return [0, 1];
};

export const getSessionId = () => localStorage.getItem("sessionId");

export const getExperienceLabel = (experience) => {
  const currentExperience = +experience;

  return currentExperience === -1
    ? "нет опыта"
    : currentExperience === 10
    ? "более 5 лет"
    : `${currentExperience} ${
        currentExperience === 1
          ? "год"
          : currentExperience === 5
          ? "лет"
          : "года"
      }`;
};

export const parseStrToThousand = (arr) => {
  return arr
    ? arr.map((value) => value.replace("$", "").replace("K", "000"))
    : [];
};

export const parseThousandToStr = (arr, isDollar) => {
  return arr
    ? arr.map((value) => `${isDollar ? "$" : ""}${value.slice(0, -3)}K`)
    : [];
};

export const changeHistoryState = (filterID) => {
  const newUrl = `${window.location.protocol}//${window.location.host}/${filterID}`;

  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const formatDate = (arg) => {
  const date = new Date(arg);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  return `${dd}.${mm}.${yyyy}`;
};
