const Pen = ({ fill, color }) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 16V13.6642L14.25 0.414214L16.5858 2.75L17.2929 3.45711L16.5858 2.75L3.33579 16H1Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Pen;
