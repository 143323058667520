import classNames from "classnames";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPopupName } from "../../Redux/actions/popupActions";
import { setMassUserIds } from "../../Redux/actions/massMessageActions";
import "./massMessageBox.scss";

const MassMessageBox = ({ className }) => {
  const dispatch = useDispatch();
  const selectedMassUserIds = useSelector(
    (state) => state.massMessageData.selectedMassUserIds
  );
  const job_position = useSelector(
    (state) => state.mainData?.params?.job_position
  );

  const handleReset = () => {
    const selectedUserJobs = localStorage.getItem("selectedUserJobs");
    const selectedUserJobsParsed = JSON.parse(selectedUserJobs);
    selectedUserJobsParsed[job_position] = [];
    localStorage.setItem(
      "selectedUserJobs",
      JSON.stringify(selectedUserJobsParsed)
    );
    dispatch(setMassUserIds([]));
  };

  return (
    <div className={classNames("right-side-selected_users", [className || ""])}>
      <p>Действия с выбранными({selectedMassUserIds?.length}):</p>
      <div>
        {" "}
        <button
          className="btn-1 button"
          onClick={() => {
            dispatch(setPopupName("SEND_MESSAGE"));
          }}>
          Отправить сообщение
        </button>
        <button className="btn-1 button" onClick={handleReset}>
          Сбросить выбранных
        </button>
      </div>
    </div>
  );
};

export default memo(MassMessageBox);
