import { getUrlFilterId } from "./utils/utils";
import Wrappers from "./Components/Wrappers/wrappers";
import "./rsuit-custom.scss";
import "./App.scss";

const urlFilterID = getUrlFilterId();
urlFilterID && localStorage.setItem("filterID", urlFilterID);
const selectedUserJobs = localStorage.getItem("selectedUserJobs");
!selectedUserJobs &&
  localStorage.setItem("selectedUserJobs", JSON.stringify({}));

function App() {
  return <Wrappers />;
}

export default App;
