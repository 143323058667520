const initialState = {
  popupSkillData: {},
  popupActionName: "",
  skillItemIndex: null,
  removedFilterId: null,
  messagePopupData: null,
  messagePopupList: null,
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_POP_UP_DATA":
      return { ...state, popupSkillData: action.payload };
    case "SET_POPUP_NAME":
      return { ...state, popupActionName: action.payload };
    case "SET_REMOVED_FILTER_ID":
      return { ...state, removedFilterId: action.payload };
    case "SET_MESSAGE_POPUP_DATA":
      return { ...state, messagePopupData: action.payload };
    case "SET_SKILL_ITEM_INDEX":
      return { ...state, skillItemIndex: action.payload };
    case "SET_MESSAGE_POPUP_LIST":
      return { ...state, messagePopupList: action.payload };

    default:
      return state;
  }
};

export default popupReducer;
