import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import { Search } from "../../Core/appRequests/appRequests";

const ResultsPagination = ({ className }) => {
  const { total_pages, activePage } = useSelector(
    (state) => state.searchResultData
  );
  const selectedFiltersData = useSelector((state) => state.selectedFiltersData);

  const handlePageChange = (pageNumber) => {
    Search({ filter_id: selectedFiltersData.filter.id, page: pageNumber });
  };

  return (
    total_pages > 1 && (
      <div className={className || null}>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={1}
          totalItemsCount={total_pages}
          pageRangeDisplayed={6}
          onChange={handlePageChange}
          nextPageText=">"
          prevPageText="<"
          firstPageText=""
          lastPageText=""
        />
      </div>
    )
  );
};

export default ResultsPagination;
