export function setChooseFilterValues(data) {
  return {
    type: "CHOOSE_FILTERS",
    payload: data,
  };
}

export function setSkillsData(data) {
  return {
    type: "SET_SKILLS_DATA",
    payload: data,
  };
}

export function saveSkillsItem(data) {
  return {
    type: "SAVE_SKILLS_ITEM",
    payload: data,
  };
}

export function saveFilterName(data) {
  return {
    type: "SET_FILTER_NAME",
    payload: data,
  };
}
