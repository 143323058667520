const initialState = {
  total_pages: 1,
  items: [],
  activePage: 1
};

const searchResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_RESULT_DATA":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default searchResultReducer;
