import {
  memo,
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
} from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { RangeSlider, Slider } from "rsuite";
import Pen from "../../../Assets/icons/Pen";
import Close from "../../../Assets/icons/Close";
import {
  getFilters,
  Search,
  updateCurrentFilter,
} from "../../../Core/appRequests/appRequests";
import { setChooseFilterValues } from "../../../Redux/actions/filterDataActions";
import Select from "../../select/select";
import {
  changeHistoryState,
  generateSessionId,
  getArrayValue,
  getIndex,
  getIndexes,
  getSessionId,
  isWithUserId,
} from "../../../utils/utils";
import SkillItem from "../../skillItem/skillItem";
import {
  setPopupName,
  setPopupData,
} from "../../../Redux/actions/popupActions";
import "./leftSide.scss";
import RefreshIcon from "../../../Assets/icons/Refresh";

const LeftSide = ({ openFilter, setOpenFilter }) => {
  const ref = useRef({});
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState("rub");
  const [isInteractive, setIsInteractive] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const data = useSelector((state) => state.mainData);
  const { isShowLoading } = useSelector((state) => state.loadingData);
  const selectedFiltersData = useSelector((state) => state.selectedFiltersData);
  const { id: filterID, name: filterName } = selectedFiltersData?.filter || {};

  const {
    match_levels,
    salary_options_rub,
    salary_options_usd,
    job_positions,
    english_levels,
    job_position_levels,
    skills,
    skill_levels,
    experiences,
  } = data?.options || {};

  const { salary_range_rub } = data?.params || {};

  const salaryAmounts =
    currency === "rub" ? salary_options_rub : salary_options_usd;

  const setFilterValue = useCallback(
    (value, name, array) => {
      const result = Array.isArray(array)
        ? value.map((item) => array[item - 1])
        : value;

      dispatch(setChooseFilterValues({ [name]: result }));
      setIsInteractive(true);
    },
    [dispatch]
  );

  const getSelectedOptions = useCallback(
    (key, array) => selectedFiltersData[key] || (array && array[0]),
    [selectedFiltersData]
  );

  const setSpecifySkills = () => {
    const defaultSkills = {};
    defaultSkills.title = skills[0];
    defaultSkills.level = +skill_levels[0];
    defaultSkills.desire = true;
    defaultSkills.experience = +experiences[0];
    batch(() => {
      dispatch(setPopupData(defaultSkills));
      dispatch(setPopupName("SKILL_ACTION"));
    });
  };

  const handleGetFilterData = async () => {
    const sessionId = getSessionId();
    if (!sessionId) {
      generateSessionId();
      await getFilters();
      updateCurrentFilter();
    } else {
      getFilters();
    }
  };

  const handleUpdateFilter = async () => {
    const callback = (data) => {
      ref.current.prevFilterData = { ...data };
    };

    isWithUserId() && changeHistoryState(filterID);

    setOpenFilter(false);
    await updateCurrentFilter(callback);
    filterID && Search({ filter_id: filterID });
    setIsFilterChanged(false);
  };

  const filterIdChanged = async (filterID) => {
    if (filterID) {
      const { prevFilterId } = ref.current;
      const withUserId = isWithUserId();
      const isFilterChanged = prevFilterId && prevFilterId !== filterID;

      localStorage.setItem("filterID", filterID);
      (!withUserId || (withUserId && isFilterChanged)) &&
        changeHistoryState(filterID);

      await getFilters();
      filterID && Search({ filter_id: filterID });
      ref.current.prevFilterId = filterID;
    }
  };

  useEffect(() => {
    handleGetFilterData();
  }, []);

  useEffect(() => {
    filterName && localStorage.setItem("filterName", filterName);
  }, [filterName]);

  useEffect(() => {
    filterIdChanged(filterID);
    setOpenFilter(false);
  }, [filterID]);

  useEffect(() => {
    let prevFilterData = ref?.current?.prevFilterData;
    const isSameFilter =
      prevFilterData?.filter?.id === selectedFiltersData?.filter?.id;

    !isSameFilter && setIsInteractive(false);

    if ((!isSameFilter || !isInteractive) && selectedFiltersData?.filter?.id) {
      ref.current.prevFilterData = selectedFiltersData;
      setIsFilterChanged(false);
    }

    let currentFilter = { ...selectedFiltersData };
    let prevFilter = { ...prevFilterData };

    delete currentFilter.filter;
    delete prevFilter.filter;

    prevFilterData?.filter?.id &&
      setIsFilterChanged(
        isInteractive &&
          JSON.stringify(currentFilter) !== JSON.stringify(prevFilter)
      );
  }, [ref, isInteractive, selectedFiltersData]);

  return (
    <>
      <div
        className={`left-side ${openFilter ? "animated" : ""}`}
        style={
          isShowLoading ? { opacity: "0.7", pointerEvents: "none" } : null
        }>
        {openFilter ? (
          <span className="filter-close" onClick={() => setOpenFilter(false)}>
            <Close />
          </span>
        ) : null}
        <div className="filters">
          <div className="filter-box">
            <h3 className="title">IT специализация</h3>
            <Select
              values={job_positions}
              optionName="job_position"
              setFilterValue={setFilterValue}
              selectedOption={getSelectedOptions("job_position", job_positions)}
            />
          </div>

          <div className="filter-box">
            <h3 className="title">Уровень</h3>
            <Select
              values={job_position_levels}
              optionName="job_position_level"
              setFilterValue={setFilterValue}
              selectedOption={getSelectedOptions(
                "job_position_level",
                job_position_levels
              )}
            />
          </div>

          <div className="filter-box">
            <div className="title-box salary">
              <h3 className="title">Ожидаемый уровень ЗП</h3>
              <span className="currency">
                <span
                  className={currency === "rub" ? "" : "active"}
                  onClick={() => setCurrency("rub")}>
                  руб.
                </span>
                <span className="currency-line">|</span>
                <span
                  className={currency === "usd" ? "" : "active"}
                  onClick={() => setCurrency("usd")}>
                  долл.
                </span>
              </span>
            </div>

            <div className="price">
              <RangeSlider
                max={salaryAmounts?.length}
                min={1}
                defaultValue={getIndexes(salaryAmounts, salary_range_rub)}
                value={getIndexes(
                  salaryAmounts,
                  selectedFiltersData["salary_range_" + currency]
                )}
                graduated
                progress
                onChange={(e) =>
                  setFilterValue(e, "salary_range_" + currency, salaryAmounts)
                }
                renderMark={(mark) => (
                  <span>{salaryAmounts && salaryAmounts[mark - 1]}</span>
                )}
              />
            </div>
            <div className="checkboxes">
              <div>
                <label value="1">Готов к переезду</label>
                <input
                  type="checkbox"
                  value={selectedFiltersData.ready_to_relocate}
                  checked={selectedFiltersData.ready_to_relocate}
                  onChange={(e) =>
                    setFilterValue(e.target.checked, "ready_to_relocate")
                  }
                />
              </div>

              <div>
                <label value="2">Находится не в РФ</label>
                <input
                  type="checkbox"
                  value={selectedFiltersData.in_russia}
                  checked={selectedFiltersData.in_russia}
                  onChange={(e) =>
                    setFilterValue(e.target.checked, "in_russia")
                  }
                />
              </div>

              <div>
                <label value="3">Возможна частичная занятость</label>
                <input
                  type="checkbox"
                  value={selectedFiltersData.part_time}
                  checked={selectedFiltersData.part_time}
                  onChange={(e) =>
                    setFilterValue(e.target.checked, "part_time")
                  }
                />
              </div>
            </div>

            <div className="filter-box">
              <h3 className="title">Уровень знания английского</h3>
              <Select
                values={english_levels}
                optionName="english_level"
                setFilterValue={setFilterValue}
                selectedOption={getSelectedOptions(
                  "english_level",
                  english_levels
                )}
              />
            </div>

            <div className="filter-box">
              <h3 className="title">Фильтр по навыкам</h3>
              <div className="skills">
                {selectedFiltersData?.skills?.map((item, i) => (
                  <Fragment key={i}>
                    <SkillItem item={item} index={i} />
                  </Fragment>
                ))}

                <button type="button" onClick={setSpecifySkills}>
                  <Pen fill="#4F4F4F" color="white" /> Указать навыки
                </button>
              </div>
            </div>

            <div className="filter-box">
              <div className="title-box">
                <h3 className="title">Совпадение не менее чем </h3>
              </div>
              {match_levels && (
                <Slider
                  progress
                  graduated
                  min={1}
                  max={match_levels?.length}
                  defaultValue={getIndex(
                    match_levels,
                    selectedFiltersData?.match_level
                  )}
                  value={getIndex(
                    match_levels,
                    selectedFiltersData?.match_level
                  )}
                  onChange={(value) =>
                    setFilterValue(
                      getArrayValue(match_levels, value - 1),
                      "match_level"
                    )
                  }
                  renderMark={(mark) => (
                    <span>{match_levels && match_levels[mark - 1]}%</span>
                  )}
                />
              )}
            </div>
          </div>

          <button
            type="button"
            className="refreshBtn"
            onClick={handleUpdateFilter}>
            Обновить
          </button>

          <Select
            isWithActions
            showAddFilter
            isRemoveDisabled={data?.filters?.length < 2}
            values={data?.filters}
            nameKey="name"
            optionName="filter"
            setFilterValue={setFilterValue}
            selectedOption={selectedFiltersData?.filter?.name}
          />
        </div>
      </div>
      {isFilterChanged && !isShowLoading && (
        <button
          type="button"
          className="refreshBtn fixed"
          onClick={handleUpdateFilter}>
          <RefreshIcon />
          <span>Обновить</span>
        </button>
      )}
    </>
  );
};

export default memo(LeftSide);
