import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMassMessage } from "../../Core/appRequests/appRequests";
import { setPopupName } from "../../Redux/actions/popupActions";

const SendMassMessagePopup = () => {
  const dispatch = useDispatch();
  const { selectedMassUserIds, loading } = useSelector(
    (state) => state.massMessageData
  );
  const job_position = useSelector(
    (state) => state.selectedFiltersData?.job_position
  );
  const [text, setText] = useState();
  const handleClose = () => dispatch(setPopupName());

  const handleSend = () => {
    sendMassMessage({
      user_ids: selectedMassUserIds,
      message: text,
      job_position,
    });
  };

  return (
    <>
      <div className="mass_message_popup-head">
        Отправить сообщение выбранным({selectedMassUserIds?.length})
      </div>
      <div className="mass_message_popup-body">
        <textarea
          placeholder="Введите текст сообщения(тут textarea)"
          value={text}
          onChange={(e) => setText(e.target.value)}></textarea>
      </div>
      <div className="mass_message_popup-footer">
        <button className="button btn-2" onClick={handleClose}>
          Отменить
        </button>
        <button
          className={classNames("button btn-3", {
            disable:
              !text || !job_position || !selectedMassUserIds.length || loading,
          })}
          onClick={handleSend}>
          Отправить
        </button>
      </div>
    </>
  );
};

export default SendMassMessagePopup;
