const initialState = {
  loading: false,
  selectedMassUserIds: [],
  failureMessage: undefined,
};

const massMessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_MASS_IDS":
      return { ...state, selectedMassUserIds: action.payload };
    case "SET_FAILURE_MASS_MESSAGE":
      return { ...state, failureMessage: action.payload };
    case "SET_MASS_MESSAGE_LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default massMessageReducer;
