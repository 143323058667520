const Pick = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 9.475L2.025 6L0.841667 7.175L5.5 11.8333L15.5 1.83333L14.325 0.658333L5.5 9.475Z"
        fill="#56CCF2"
      />
    </svg>
  );
};

export default Pick;
