import { memo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import Delete from "../../Assets/icons/Delete";
import Pen from "../../Assets/icons/Pen";
import { saveSkillsItem } from "../../Redux/actions/filterDataActions";
import {
  setPopupName,
  setPopupData,
  setSkillItemIndex,
} from "../../Redux/actions/popupActions";
import { getExperienceLabel } from "../../utils/utils";

const SkillItem = ({ item, index }) => {
  const dispatch = useDispatch();
  const selectedFiltersData = useSelector((state) => state.selectedFiltersData);

  const handleDeleteSkillsItem = (index) => {
    const skills = [...selectedFiltersData?.skills];
    skills.splice(index, 1);
    dispatch(saveSkillsItem(skills));
  };

  const handleUpdateSkillItem = (index) => {
    const updatingItem = selectedFiltersData.skills.find((_, i) => i === index);

    batch(() => {
      dispatch(setPopupData(updatingItem));
      dispatch(setSkillItemIndex(index));
      dispatch(setPopupName("SKILL_ACTION"));
    });
  };

  return (
    <div className="skills-box">
      <div className={`skills-name ${!item.desire ? "no-experience" : ""}`}>
        {item.title}
        <p className="skills-info">
          <span>{`${item.level}/5`}</span>

          <span>{getExperienceLabel(item.experience)}</span>
        </p>
      </div>
      <div className="actions">
        <span onClick={() => handleDeleteSkillsItem(index)}>
          <Delete />
        </span>
        <span onClick={() => handleUpdateSkillItem(index)}>
          <Pen fill="white" color="#4F4F4F" />
        </span>
      </div>
    </div>
  );
};

export default memo(SkillItem);
