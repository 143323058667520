import React from "react";
import { useDispatch } from "react-redux";
import { setPopupName } from "../../Redux/actions/popupActions";

const MassMessageSuccess = () => {
  const dispatch = useDispatch();

  const handleClose = () => dispatch(setPopupName());

  return (
    <>
      <div className="mass_message_popup-head">Сообщение отправлено</div>
      <div className="mass_message_popup-body">
        Оно будет доставлено адресатам сразу после проверки.
      </div>
      <div className="mass_message_popup-footer">
        <button className="button btn-3" onClick={handleClose}>
          Закрыть
        </button>
      </div>
    </>
  );
};

export default MassMessageSuccess;
