import classNames from "classnames";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMassUserIds } from "../../Redux/actions/massMessageActions";
import "./index.scss";

const SelectAll = () => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const job_position = useSelector(
    (state) => state.mainData?.params?.job_position
  );

  const all_user_ids = useSelector(
    (state) => state.searchResultData?.all_user_ids
  );

  const selectedMassUserIds = useSelector(
    (state) => state.massMessageData.selectedMassUserIds
  );

  const isAuth = !!useSelector((state) => state.mainData?.auth_data?.id);

  const handleSelect = () => {
    isAuth &&
      dispatch(
        !isSelected
          ? setMassUserIds([
              ...new Set([...selectedMassUserIds, ...all_user_ids]),
            ])
          : setMassUserIds(
              selectedMassUserIds.filter(
                (massId) => !all_user_ids.includes(massId)
              )
            )
      );
  };

  useEffect(() => {
    if (all_user_ids?.length && selectedMassUserIds?.length) {
      const isSelected = all_user_ids.every((id) =>
        selectedMassUserIds.includes(id)
      );
      setIsSelected(isSelected);
    } else {
      setIsSelected(false);
    }
  }, [selectedMassUserIds, all_user_ids]);

  return (
    <div
      className={classNames("select-all", {
        disabled: !isAuth,
      })}
      onClick={handleSelect}>
      <input type="checkbox" value={isSelected} checked={isSelected} readOnly />
      <label value="1">{`Выбрать всех (${job_position})`}</label>
    </div>
  );
};

export default SelectAll;
