const Close = ({ color = "#000000" }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9254 0.958332L6.5 5.38375L2.07458 0.958332L0.958328 2.07458L5.38375 6.5L0.958328 10.9254L2.07458 12.0417L6.5 7.61625L10.9254 12.0417L12.0417 10.9254L7.61624 6.5L12.0417 2.07458L10.9254 0.958332Z"
        fill={color}
      />
    </svg>
  );
};

export default Close;
