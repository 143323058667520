import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMassUserIds } from "../../../Redux/actions/massMessageActions";
import Loading from "../../loading/loading";
import ResultsPagination from "../../pagination/resultsPagination";
import Result from "../../result/result";
import Sorting from "../../sorting/sorting";
import MassMessageBox from "../../massMessageBox/massMessageBox";
import SelectAll from "../../selectAll";
import "./rightSide.scss";

let isCreated = false;

const RightSide = () => {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.searchResultData);
  const isShowLoading = useSelector(
    (state) => state.loadingData?.isShowLoading
  );
  const job_position = useSelector(
    (state) => state.mainData?.params?.job_position
  );
  const selectedMassUserIds = useSelector(
    (state) => state.massMessageData.selectedMassUserIds
  );

  const handleSelected = (user_id) => {
    const users = [...selectedMassUserIds];
    const index = users.indexOf(user_id);
    index !== -1 ? users.splice(index, 1) : users.push(user_id);
    dispatch(setMassUserIds(users));
  };

  useEffect(() => {
    if (job_position) {
      const selectedUserJobs = localStorage.getItem("selectedUserJobs");
      const selectedUserJobsParsed = JSON.parse(selectedUserJobs);
      const currentSelectedJobs = selectedUserJobsParsed[job_position];
      if (currentSelectedJobs) {
        dispatch(setMassUserIds([...currentSelectedJobs]));
      } else {
        dispatch(setMassUserIds([]));
        localStorage.setItem(
          "selectedUserJobs",
          JSON.stringify({ ...selectedUserJobsParsed, [job_position]: [] })
        );
      }
    }
  }, [job_position]);

  useEffect(() => {
    if (job_position) {
      let selectedUserJobs = localStorage.getItem("selectedUserJobs");
      selectedUserJobs = JSON.parse(selectedUserJobs);
      if (selectedUserJobs[job_position]) {
        selectedUserJobs[job_position] = selectedMassUserIds;
        localStorage.setItem(
          "selectedUserJobs",
          JSON.stringify(selectedUserJobs)
        );
      }
    }
  }, [selectedMassUserIds, job_position]);

  useEffect(() => {
    if (isShowLoading) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [isShowLoading]);

  useEffect(() => {
    if (!isCreated) {
      setTimeout(() => {
        const telegram = document.querySelector(".telegram");
        const targetNode = document.querySelector(".right-side-header");
        if (targetNode) {
          targetNode.appendChild(telegram);
        }
      }, 500);
      isCreated = true;
    }
  }, []);

  return (
    <div className="right-side">
      <div className="right-side-header">
        <ResultsPagination />
      </div>
      <Sorting loading={isShowLoading} />
      {!!selectedMassUserIds?.length && <MassMessageBox />}
      <SelectAll />
      {isShowLoading ? (
        <Loading />
      ) : (
        <>
          {items?.map((data, i) => (
            <Result
              key={i}
              isLastItem={items.length === i + 1}
              data={{
                ...data,
                user_id: data.user_id,
              }}
              isSelected={selectedMassUserIds.includes(data.user_id)}
              setSelected={handleSelected}
            />
          ))}
        </>
      )}
      {!!selectedMassUserIds?.length && (
        <MassMessageBox className="bottom-mass-box" />
      )}

      <ResultsPagination className="bottom-pagination" />
    </div>
  );
};

export default memo(RightSide);
