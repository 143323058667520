import { batch } from "react-redux";
import store from "../../Redux/store";
import {
  setMessageLoading,
  setShowLoading,
} from "../../Redux/actions/loadingAction";
import { setMainData } from "../../Redux/actions/mainDataActions.js";
import { saveResultsData } from "../../Redux/actions/searchResultActions";
import {
  saveFilterName,
  setChooseFilterValues,
} from "../../Redux/actions/filterDataActions";
import {
  getAuthDataStorage,
  getSessionId,
  getUrlUserrId,
  isWithUserId,
  parseStrToThousand,
  parseThousandToStr,
} from "../../utils/utils";
import {
  setMessagePopupList,
  setPopupName,
} from "../../Redux/actions/popupActions";
import {
  setMassMessageFailure,
  setMassMessageLoading,
} from "../../Redux/actions/massMessageActions";

const URL = "https://api.skilldir.com/";
let prevSortType;

export const getFilters = () => {
  const { dispatch, getState } = store;
  const state = getState();

  dispatch(setShowLoading(true));

  const storageFilterId = localStorage.getItem("filterID");
  const selectedFiltersData = state.selectedFiltersData || {};
  const filter = selectedFiltersData?.filter || {};
  const filterId = filter.id || storageFilterId;

  return fetch(`${URL}get_filter`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: filterId,
      session_id: getSessionId(),
      auth_data: getAuthDataStorage(),
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      const { params, filters, options, id, name } = data || {};
      const currentFilterData = { ...params };
      currentFilterData.skills = currentFilterData.skills || [];
      let currentFilter = {};
      const storageId = localStorage.getItem("filterID");

      if (storageId) {
        currentFilter.id = id;
        currentFilter.name = name;
      } else {
        currentFilter = filter.id ? filter : (filters && filters[0]) || filter;
      }
      currentFilterData.filter = currentFilter;
      if (!currentFilter.id) {
        currentFilter.id = filterId;
      }
      if (!currentFilter.name) {
        currentFilter.name = "по умолчанию";
      }

      if (options) {
        const {
          english_levels,
          job_positions,
          job_position_levels,
          salary_options_rub,
          salary_options_usd,
        } = options;

        currentFilterData.english_level =
          currentFilterData.english_level || english_levels[0];
        currentFilterData.job_position =
          currentFilterData.job_position || job_positions[0];
        currentFilterData.job_position_level =
          currentFilterData.job_position_level || job_position_levels[0];
        currentFilterData.match_level = currentFilterData.match_level || 10;
        currentFilterData.salary_range_rub =
          currentFilterData.salary_range_rub ||
          parseStrToThousand([
            salary_options_rub[0],
            salary_options_rub[salary_options_rub.length - 1],
          ]);
        currentFilterData.salary_range_usd =
          currentFilterData.salary_range_usd ||
          parseStrToThousand([
            salary_options_usd[0],
            salary_options_usd[salary_options_usd.length - 1],
          ]);
      }

      currentFilterData.salary_range_rub = parseThousandToStr(
        currentFilterData?.salary_range_rub
      );
      currentFilterData.salary_range_usd = parseThousandToStr(
        currentFilterData?.salary_range_usd,
        true
      );

      batch(() => {
        dispatch(setMainData(data));
        dispatch(setChooseFilterValues(currentFilterData));
      });
    })

    .finally(() => {
      dispatch(setShowLoading(false));
    });
};

export const updateCurrentFilter = (callback) => {
  const { dispatch, getState } = store;
  const state = getState();

  dispatch(setShowLoading(true));

  const selectedFiltersData = state.selectedFiltersData || {};
  const filter = selectedFiltersData?.filter || {};

  const data = {
    ...filter,
    session_id: getSessionId(),
    params: {
      ...selectedFiltersData,
      salary_range_rub: parseStrToThousand(
        selectedFiltersData?.salary_range_rub
      ),
      salary_range_usd: parseStrToThousand(
        selectedFiltersData?.salary_range_usd,
        true
      ),
    },
    auth_data: getAuthDataStorage(),
  };

  const ignoreKeys = [
    "filter",
    "english_levels",
    "skill_levels",
    "experiences",
    "match_levels",
    "job_position_levels",
    "job_positions",
  ];

  for (let key in data.params) {
    if (ignoreKeys.includes(key)) {
      delete data.params[key];
    }
  }

  return fetch(`${URL}update_filter`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then(async (data) => {
      const { id, name } = data;
      const filterRes = { id, name };

      if (filter.id) {
        await getFilters();
      }
      dispatch(setChooseFilterValues({ filter: filterRes }));
      typeof callback === "function" && callback(selectedFiltersData);
    })
    .finally(() => {
      dispatch(setShowLoading(false));
    });
};

export const Search = ({ filter_id, page, sort_by }) => {
  const { dispatch } = store;
  dispatch(setShowLoading(true));
  const activePage = page || 1;

  const body = {
    sort_by: sort_by || prevSortType,
    page: activePage,
    auth_data: getAuthDataStorage(),
  };

  if (sort_by) {
    prevSortType = sort_by;
  }

  isWithUserId()
    ? (body.user_id = getUrlUserrId())
    : (body.filter_id = filter_id);

  return fetch(`${URL}search`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(saveResultsData({ ...data, activePage }));
    })
    .finally(() => {
      dispatch(setShowLoading(false));
    });
};

export const RemoveFilter = (id) => {
  const { dispatch, getState } = store;
  const state = getState();

  const mainData = state.mainData || {};
  const filters = mainData.filters || [];
  const selectedFiltersData = state.selectedFiltersData || {};
  const selectedFilter = selectedFiltersData?.filter || {};

  dispatch(setShowLoading(true));

  return fetch(`${URL}drop_filter`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({ id, auth_data: getAuthDataStorage() }),
  })
    .then((response) => response.json())
    .then(() => {
      const index = filters.findIndex((item) => id === item.id);

      const resFilters = [...filters];
      resFilters.splice(index, 1);

      batch(() => {
        id === selectedFilter.id &&
          filters.length > 1 &&
          dispatch(
            setChooseFilterValues({ filter: filters[index === 0 ? 1 : 0] })
          );

        dispatch(
          setMainData({
            ...mainData,
            filters: resFilters,
          })
        );
      });
    })
    .finally(() => {
      dispatch(setShowLoading(false));
    });
};

export const ChangeFilterName = (id, name) => {
  const { dispatch, getState } = store;
  const state = getState();

  dispatch(setShowLoading(true));

  const mainData = state.mainData || {};
  const filters = mainData.filters || [];
  const selectedFiltersData = state.selectedFiltersData || {};
  const selectedFilter = selectedFiltersData?.filter || {};

  const data = {
    ...selectedFilter,
    params: {
      ...selectedFiltersData,
      salary_range_rub: parseStrToThousand(
        selectedFiltersData?.salary_range_rub
      ),
      salary_range_usd: parseStrToThousand(
        selectedFiltersData?.salary_range_usd,
        true
      ),
    },
    id,
    name,
    auth_data: getAuthDataStorage(),
    session_id: getSessionId(),
  };

  return fetch(`${URL}update_filter`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then(() => {
      const index = filters.findIndex((item) => id === item.id);
      id === selectedFilter.id && dispatch(saveFilterName(name));

      const resFilters = [...filters];
      resFilters[index].name = name;

      dispatch(
        setMainData({
          ...mainData,
          filters: resFilters,
        })
      );
    })
    .finally(() => {
      dispatch(setShowLoading(false));
    });
};

export const GetMessagesList = (id) => {
  const { dispatch } = store;

  // dispatch(setShowLoading(true));

  return fetch(`${URL}message_list`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      user_id: id,
      auth_data: getAuthDataStorage(),
      session_id: getSessionId(),
    }),
  })
    .then((response) => response.json())
    .then((res) => {
      res?.length && dispatch(setMessagePopupList([...res]));
    })
    .finally(() => {
      // dispatch(setShowLoading(false));
    });
};

export const SendMessagesList = (data) => {
  const { dispatch } = store;

  dispatch(setMessageLoading(true));

  return fetch(`${URL}message_add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      ...data,
      auth_data: getAuthDataStorage(),
      session_id: getSessionId(),
    }),
  })
    .then(() => {
      GetMessagesList(data.user_id);
    })
    .finally(() => {
      dispatch(setMessageLoading(false));
    });
};

export const sendMassMessage = (data) => {
  const { dispatch } = store;

  dispatch(setMassMessageLoading(true));

  return fetch(`${URL}mass_message_add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      ...data,
      auth_data: getAuthDataStorage(),
      session_id: getSessionId(),
    }),
  })
    .then((res) => {
      if (!res.ok) {
        return Promise.reject(res);
      }
      return res.json();
    })
    .then(() => {
      dispatch(setPopupName("MASS_MESSAGE_SUCCESS"));
    })
    .catch((res) => {
      res.json().then((err) => {
        dispatch(setMassMessageFailure(err.Message || err.message));
        dispatch(setPopupName("MASS_MESSAGE_FAILURE"));
      });
    })
    .finally(() => {
      dispatch(setMassMessageLoading(false));
    });
};
