export const setMassUserIds = (payload) => ({
  type: "SET_SELECTED_MASS_IDS",
  payload,
});

export const setMassMessageFailure = (payload) => ({
  type: "SET_FAILURE_MASS_MESSAGE",
  payload,
});

export const setMassMessageLoading = (payload) => ({
  type: "SET_MASS_MESSAGE_LOADING",
  payload,
});
