export function setPopupData(data) {
  return {
    type: "SET_POP_UP_DATA",
    payload: data,
  };
}

export function setPopupName(data) {
  return {
    type: "SET_POPUP_NAME",
    payload: data,
  };
}

export function setSkillItemIndex(index) {
  return {
    type: "SET_SKILL_ITEM_INDEX",
    payload: index,
  };
}

export function setRemovedFilterId(id) {
  return {
    type: "SET_REMOVED_FILTER_ID",
    payload: id,
  };
}

export function setMessagePopupData(payload) {
  return {
    type: "SET_MESSAGE_POPUP_DATA",
    payload,
  };
}

export function setMessagePopupList(payload) {
  return {
    type: "SET_MESSAGE_POPUP_LIST",
    payload,
  };
}
