import { memo, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import Filter from "../../Assets/icons/Filter";
import Popup from "../popup/popup";
import LeftSide from "./leftSide/leftSide";
import RightSide from "./rightSide/rightSide";
import PopupWrapper from "./popupWrapper/popupWrapper";
import "./wrappers.scss";

const Wrappers = () => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <>
      <PopupWrapper />
      <div className="wrappers">
        <LeftSide openFilter={openFilter} setOpenFilter={setOpenFilter} />
        {!openFilter ? (
          <>
            <span onClick={() => setOpenFilter(true)} className="filter-icon">
              <Filter />
            </span>
            <RightSide />
          </>
        ) : null}
      </div>
    </>
  );
};

export default memo(Wrappers);
