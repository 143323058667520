import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import "moment/locale/ru";
import AdminIcon from "../../Assets/icons/AdminIcon";
import Send from "../../Assets/icons/Send";
import Close from "../../Assets/icons/Close";
import { setPopupName } from "../../Redux/actions/popupActions";
import MessagRead from "../../Assets/icons/MessagRead";
import { formatDate } from "../../utils/utils";
import {
  GetMessagesList,
  SendMessagesList,
} from "../../Core/appRequests/appRequests";

const ShowMessagesPopup = () => {
  const dispatch = useDispatch();

  const contentRef = useRef();

  const [isActiveInput, setIsActiveInput] = useState(false);
  const [messageText, setMessageText] = useState("");
  const { messagePopupList, messagePopupData } = useSelector(
    (state) => state.popupData
  );
  const { isMessageLoading } = useSelector((state) => state.loadingData);
  const { user_name, user_id } = messagePopupData || {};

  const sendMessage = () => {
    dispatch(setPopupName("MESSAGE_NOT_AUTH"));
  };

  let lastMessageData;
  let firstMessageDate;
  let lastMessageDays;
  let formatLastMessageDate;
  const isValidMessageSend = isActiveInput && messageText && !isMessageLoading;

  if (messagePopupList) {
    firstMessageDate = new Date(messagePopupList[0].creation_time);
    lastMessageData = messagePopupList[messagePopupList.length - 1];
    lastMessageDays = moment().diff(
      moment(lastMessageData.creation_time),
      "days"
    );
    formatLastMessageDate = formatDate(firstMessageDate);
  }

  const handleSendMEssage = useCallback(() => {
    if (isValidMessageSend) {
      SendMessagesList({
        user_id,
        message: messageText,
        message_reply_timestamp: lastMessageData?.message_timestamp || 0,
      });

      setMessageText("");
    }
  }, [user_id, isValidMessageSend, messageText, lastMessageData]);

  useEffect(() => {
    if (messagePopupList && contentRef) {
      const current = contentRef.current;
      current.scrollTop = current.scrollHeight;
    }
  }, [messagePopupList, contentRef]);

  useEffect(() => {
    if (isValidMessageSend) {
      const callback = (e) => {
        e.preventDefault();
        if (e.keyCode === 13) {
          handleSendMEssage();
        }
      };

      document.addEventListener("keyup", callback);
      return () => document.removeEventListener("keyup", callback);
    }
  }, [isValidMessageSend, handleSendMEssage]);

  useEffect(() => {
    const interval = setInterval(() => {
      GetMessagesList(user_id);
    }, 10000);

    return () => clearInterval(interval);
  });

  return (
    <div className="messages-list-wrapper">
      <div className="messages-list-wrapper-head">
        <p>{user_name}</p>
        <span>последнее сообщение {lastMessageDays || 0} дня назад</span>
      </div>
      <div className="messages-list-wrapper-content" ref={contentRef}>
        <div className="messages-list-wrapper-content-last_message_date">
          {formatLastMessageDate}
        </div>
        {messagePopupList?.length ? (
          messagePopupList?.map((messageData, index) => {
            const isAdmin = messageData.from_user_id === user_id;
            const date = new Date(messageData.creation_time);
            const time = moment(date)
              .locale("ru")
              .format("DD MMMM YYYY в hh:mm");

            return (
              <div
                key={index}
                className={classNames(
                  {
                    admin: isAdmin,
                    opponent: !isAdmin,
                  },
                  "message"
                )}>
                {isAdmin && <AdminIcon />}
                <div className="message-content">
                  {isAdmin && (
                    <div className="message-content-head">
                      <p className="message-content-head-name">{user_name}</p>
                      <span>Соискатель</span>
                    </div>
                  )}
                  <div className="message-content-wrapper">
                    <p className="message-content-wrapper-text">
                      {messageData.message}
                    </p>
                    <div className="message-content-wrapper-date">
                      {time}{" "}
                      {!isAdmin && (
                        <MessagRead fill={!messageData.isRead && "#e9e9e9"} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>Пока нет переписки с данным соискателем</p>
        )}
      </div>
      <div className="messages-list-wrapper-footer">
        {isActiveInput ? (
          <input
            autoFocus
            type="text"
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
        ) : (
          <p>
            <a
              href=""
              rel="noreferrer"
              onClick={(e) => {
                e.preventDefault();
                setIsActiveInput(true);
              }}>
              Отправить сообщение тут
            </a>
            <span>или</span>
            <a
              target="_blank"
              rel="noreferrer"
              onClick={!user_id ? sendMessage : null}
              href={
                user_id
                  ? `https://t.me/SkillDirectoryBot?start=message_${user_id}`
                  : null
              }>
              через Telegram бота
            </a>
          </p>
        )}
        {isActiveInput && (
          <div
            className="close-input"
            onClick={() => {
              setIsActiveInput(false);
              setMessageText("");
            }}>
            <Close color="#8E8E93" />
          </div>
        )}
        {isActiveInput && (
          <div
            className={classNames("send-message-button", {
              disabled: isMessageLoading || !messageText,
            })}
            onClick={handleSendMEssage}>
            <Send />
          </div>
        )}
        <div />
      </div>
    </div>
  );
};

export default memo(ShowMessagesPopup);
