import React from "react";

const Edit = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 5.33501C18.2507 5.21986 18.2286 5.1057 18.1851 4.9991C18.1415 4.89249 18.0774 4.79552 17.9963 4.71376L14.2863 1.00376C14.2045 0.922668 14.1075 0.858509 14.0009 0.814965C13.8943 0.771421 13.7802 0.749349 13.665 0.750015C13.5499 0.749349 13.4357 0.771421 13.3291 0.814965C13.2225 0.858509 13.1255 0.922668 13.0438 1.00376L10.5675 3.48001L1.00376 13.0438C0.922668 13.1255 0.858509 13.2225 0.814965 13.3291C0.771421 13.4357 0.749349 13.5499 0.750015 13.665V17.375C0.750015 17.6071 0.842202 17.8296 1.0063 17.9937C1.17039 18.1578 1.39295 18.25 1.62501 18.25H5.33501C5.45745 18.2567 5.57992 18.2375 5.69448 18.1938C5.80904 18.1501 5.91314 18.0828 6.00001 17.9963L15.5113 8.43251L17.9963 6.00001C18.0761 5.91521 18.1412 5.81761 18.1888 5.71126C18.1972 5.64152 18.1972 5.57101 18.1888 5.50126C18.1929 5.46053 18.1929 5.4195 18.1888 5.37876L18.25 5.33501ZM4.97626 16.5H2.50001V14.0238L11.1888 5.33501L13.665 7.81126L4.97626 16.5ZM14.8988 6.57751L12.4225 4.10126L13.665 2.86751L16.1325 5.33501L14.8988 6.57751Z"
        fill="#46A784"
      />
    </svg>
  );
};

export default Edit;
