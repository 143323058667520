import { memo, useState, useRef, useEffect, useCallback } from "react";
import { batch, useDispatch } from "react-redux";
import Edit from "../../Assets/icons/Edit";
import Remove from "../../Assets/icons/Remove";
import dropDownIcon from "../../Assets/images/drop-down.png";
import {
  ChangeFilterName,
  updateCurrentFilter,
} from "../../Core/appRequests/appRequests";
import { saveFilterName } from "../../Redux/actions/filterDataActions";
import {
  setPopupName,
  setRemovedFilterId,
} from "../../Redux/actions/popupActions";
import "./select.scss";

const Select = ({
  values,
  setFilterValue,
  selectedOption,
  optionName,
  valueKey,
  nameKey,
  showAddFilter = false,
  isWithActions,
  isRemoveDisabled,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const [activeEditableId, setActiveEditableId] = useState();
  const [editableValue, setEditableValue] = useState("");
  const [filterName, setFilterName] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [addNewFilter, setAddNewFilter] = useState(false);

  const handleClickOutside = useCallback(
    (e) => {
      if (showOptions && !ref.current.contains(e.target)) {
        filterName && dispatch(saveFilterName(filterName));
        setShowOptions(false);
      }
    },
    [ref, showOptions, filterName, dispatch]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const getOptionLabel = useCallback(
    (value) =>
      value === undefined
        ? ""
        : typeof value === "boolean"
        ? value
          ? "Да"
          : "Нет"
        : String(value),
    []
  );

  const handleChangeFIlterName = (e, doCall) => {
    if (e.keyCode === 13 || doCall) {
      dispatch(saveFilterName(filterName));
      setShowOptions(false);
      updateCurrentFilter(e);
    }
  };

  const handleEditValue = (e) => {
    const value = e.target.value;
    setEditableValue(value);
  };

  const handleEdit = (item) => {
    setEditableValue(item.name);
    setActiveEditableId(item.id);
  };

  const handleRemove = (item) => {
    batch(() => {
      dispatch(setPopupName("REMOVE_FILTER"));
      dispatch(setRemovedFilterId(item?.id));
    });
  };

  const handleSave = (item) => {
    setActiveEditableId(null);
    setEditableValue("");
    ChangeFilterName(item.id, editableValue);
  };

  return (
    <div className="select" ref={ref}>
      <div
        className="selected-option"
        onClick={() => {
          setShowOptions((state) => !state);
          setAddNewFilter(false);
        }}>
        {getOptionLabel(selectedOption) || ""}
        <span className={showOptions ? "open" : ""}>
          <img src={dropDownIcon} alt="" />
        </span>
      </div>
      {showOptions ? (
        <div className="options">
          {values?.map((item, i) => (
            <div
              className="options-item"
              key={i}
              onClick={() => {
                setFilterName("");
                setFilterValue(valueKey ? item[valueKey] : item, optionName);
                setShowOptions(false);
              }}>
              {item.id && item.id === activeEditableId ? (
                <input
                  autoFocus
                  type="text"
                  className="options-name"
                  value={editableValue}
                  onChange={handleEditValue}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      handleSave(item);
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                getOptionLabel(nameKey ? item[nameKey] : item)
              )}
              {isWithActions && (
                <div
                  className="options-actions"
                  onClick={(e) => e.stopPropagation()}>
                  {item.id && item.id === activeEditableId ? (
                    <>
                      <span onClick={() => handleSave(item)}>Save</span>
                      <span
                        onClick={() => {
                          setActiveEditableId(null);
                          setEditableValue("");
                        }}>
                        Close
                      </span>
                    </>
                  ) : (
                    <>
                      <span onClick={() => handleEdit(item)}>
                        <Edit />
                      </span>
                      <span
                        className={isRemoveDisabled ? "disabled" : ""}
                        onClick={() => !isRemoveDisabled && handleRemove(item)}>
                        <Remove />
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
          {showAddFilter && !addNewFilter ? (
            <p className="options-item" onClick={() => setAddNewFilter(true)}>
              Добавить новый
            </p>
          ) : null}
          {addNewFilter ? (
            <div className="new-filter">
              <input
                autoFocus
                placeholder="Добавить новый"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                onKeyUp={handleChangeFIlterName}
              />
              <button onClick={(e) => handleChangeFIlterName(e, true)}>
                Add
              </button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default memo(Select);
