import { useCallback, useState, memo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Slider } from "rsuite";
import {
  saveSkillsItem,
  setSkillsData,
} from "../../Redux/actions/filterDataActions";
import Select from "../select/select";
import { setPopupName } from "../../Redux/actions/popupActions";
import { getArrayValue, getExperienceLabel, getIndex } from "../../utils/utils";

const Skills = ({ data }) => {
  const dispatch = useDispatch();

  const selectedFiltersData = useSelector((state) => state.selectedFiltersData);
  const { popupSkillData, skillItemIndex } = useSelector(
    (state) => state.popupData
  );
  const { skill_levels, skills, experiences } = data.options || {};
  const [skillsValue, setSkillsValue] = useState(popupSkillData);

  const setSkillsValues = useCallback((value, name) => {
    setSkillsValue((state) => ({
      ...state,
      [name]: name === "level" ? +value : value,
    }));
  }, []);

  const handleSave = (isClose) => {
    const existingSkill = selectedFiltersData.skills.find(
      (item) => item.title === skillsValue.title
    );

    if (existingSkill) {
      return dispatch(setPopupName());
    }

    dispatch(setSkillsData(skillsValue));
    isClose && dispatch(setPopupName());
  };

  const handleUpdate = () => {
    const currentSkills = selectedFiltersData.skills;
    currentSkills[skillItemIndex] = skillsValue;

    batch(() => {
      dispatch(saveSkillsItem(currentSkills));
      dispatch(setPopupName());
    });
  };

  const isEdit = typeof skillItemIndex === "number";

  return (
    <div className="skills">
      <h3>Навыки</h3>
      <p>Выбока по навыкам</p>
      <div className="options">
        <div>
          <label>Навык</label>
          <Select
            values={skills}
            setFilterValue={setSkillsValues}
            selectedOption={skillsValue?.title}
            optionName="title"
          />
        </div>
        <div>
          <label>Оценка</label>
          <Select
            values={skill_levels}
            setFilterValue={setSkillsValues}
            selectedOption={skillsValue?.level}
            optionName="level"
          />
        </div>
        <div>
          <label>Желание</label>
          <Select
            values={[true, false]}
            setFilterValue={setSkillsValues}
            selectedOption={skillsValue?.desire}
            optionName="desire"
          />
        </div>
        <div className="radio-btn">
          <label>Опыт, лет</label>
          <div className="skills-slider">
            <Slider
              min={1}
              max={experiences?.length}
              progress
              graduated
              value={getIndex(experiences, String(skillsValue?.experience))}
              defaultValue={getIndex(
                experiences,
                String(skillsValue?.experience)
              )}
              renderMark={(mark) => (
                <span>{getExperienceLabel(experiences[mark - 1])}</span>
              )}
              onChange={(value) =>
                setSkillsValues(
                  getArrayValue(experiences, value - 1),
                  "experience"
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="btns">
        {!isEdit && (
          <button type="button" onClick={() => handleSave(false)}>
            Добавить в фильтр
          </button>
        )}

        {isEdit ? (
          <button type="button" onClick={handleUpdate}>
            Обновить выбор
          </button>
        ) : (
          <button type="button" onClick={() => handleSave(true)}>
            Сохранить и закрыть
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(Skills);
