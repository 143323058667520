import React from "react";

const MessagRead = ({ fill }) => {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7931 1.00044L4.63338 8.87895L1.142 5.53963"
        stroke={fill || "#81E299"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.74 1L9.57994 8.87892L6.98382 6.42004"
        stroke={fill || "#81E299"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessagRead;
