import classNames from "classnames";
import { memo } from "react";
import { batch, useDispatch } from "react-redux";
import Close from "../../Assets/icons/Close";
import Pick from "../../Assets/icons/Pick";
import { GetMessagesList } from "../../Core/appRequests/appRequests";
import {
  setMessagePopupData,
  setPopupName,
} from "../../Redux/actions/popupActions";
import { formatDate, getExperienceLabel } from "../../utils/utils";
import "./result.scss";

const Result = ({ data, isSelected, setSelected, keyIndex, isLastItem }) => {
  const dispatch = useDispatch();

  const {
    job_position_level,
    job_position,
    skills,
    match_level,
    english_level,
    date_update,
    salary_range_rub,
    salary_range_usd,
    ready_to_relocate,
    married,
    part_time,
    in_russia,
    major_traits,
    user_id,
    unread_messages,
    user_name,
  } = data;

  const defaultText = "Не указан";

  let formattedDate;
  if (date_update !== "0001-01-01T00:00:00Z") {
    formattedDate = formatDate(date_update);
  }

  const handleWrite = () => {
    batch(() => {
      if (user_id) {
        GetMessagesList(user_id);
        dispatch(
          setMessagePopupData({
            user_id,
            user_name,
            dateToShow: formattedDate,
          })
        );
        dispatch(setPopupName("MESSAGES_LIST"));
      } else {
        dispatch(setPopupName("MESSAGE_NOT_AUTH"));
      }
    });
  };

  return (
    <div
      className={classNames("filters-result", {
        last: isLastItem,
      })}
      key={keyIndex}>
      <div className="filters-result-header">
        <div>
          <p className={`growth ${job_position_level?.toLowerCase() || ""}`}>
            {job_position_level}
          </p>
          <p className="position">{job_position}</p>
        </div>
        <div className="filters-result-header-end">
          <p className="user-name">{user_name}</p>
          <p className="coincidence">
            Совпадение: <span>{match_level}%</span>
          </p>
        </div>
      </div>

      <div className="skills">
        {!!skills?.length && (
          <div className="skills-box">
            {skills.map(({ title, level, experience, desire }, index) => (
              <div key={index} className={!desire ? "no-experience" : ""}>
                <p className="name">{title}</p>
                <div className="experience">
                  <span>{level}/5</span>
                  <span>{getExperienceLabel(experience)}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="skills-actions">
          <div
            className={classNames("skills-actions-select", {
              disabled: !user_id,
            })}
            onClick={() => user_id && setSelected(user_id)}>
            <input
              type="checkbox"
              value={isSelected}
              checked={isSelected}
              disabled={!user_id}
              readOnly
            />
            <label value="1">Выбрать</label>
          </div>
          <div
            className="skills-actions-write btn-1 button"
            onClick={(e) => {
              e.preventDefault();
              handleWrite();
            }}>
            {`Написать ${unread_messages ? "(" + unread_messages + ")" : ""}`}
          </div>
        </div>
      </div>

      <div className="info">
        <div className="lang">
          <p>
            English: <span>{english_level || defaultText}</span>
          </p>
          {formattedDate && <p>Обновлено: {formattedDate}</p>}
        </div>

        <div className="work-conditions">
          {(salary_range_rub || salary_range_usd) && (
            <p className="price">
              {salary_range_rub} т.р. / {salary_range_usd} $
            </p>
          )}
          <p className="price result-option">
            {in_russia ? (
              <>
                <Pick /> в РФ
              </>
            ) : (
              <>
                <Close color="#EB5757" /> Не в РФ
              </>
            )}
          </p>
          <p className="result-option">
            {ready_to_relocate ? (
              <>
                <Pick /> Готов к переезду
              </>
            ) : (
              <>
                <Close color="#EB5757" /> Не готов к переезду
              </>
            )}
          </p>
          <p className="result-option">
            {part_time ? (
              <>
                <Close color="#EB5757" /> Частичная занятость
              </>
            ) : (
              <>
                <Pick /> Частичная занятость
              </>
            )}
          </p>
        </div>

        <div className="personnal-info">
          <p className="features">{major_traits.join(", ")}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(Result);
