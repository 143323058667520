const initialState = {
  job_position: "",
  job_position_level: "",
  skills: [],
  match_level: 10,
  english_level: "",
  in_russia: false,
  part_time: false,
  ready_to_relocate: false,
  salary_range_rub: [],
  salary_range_usd: [],
  filter: {},
};

const selectedFilterDataReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case "POP_UP_DATA":
      return { ...state, openPopup: action.payload };
    case "CHOOSE_FILTERS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_SKILLS_DATA":
      return {
        ...state,
        skills: [...state.skills, action.payload],
      };
    case "SAVE_SKILLS_ITEM":
      return {
        ...state,
        skills: action.payload,
      };
    case "SET_FILTER_NAME":
      return {
        ...state,
        filter: {
          name: action.payload,
        },
      };
    default:
      return state;
  }
};

export default selectedFilterDataReducer;
