import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPopupName } from "../../Redux/actions/popupActions";

const MassMessageFailure = () => {
  const dispatch = useDispatch();

  const failureMessage = useSelector(
    (state) => state.massMessageData.failureMessage
  );

  const handleClose = () => dispatch(setPopupName());

  return (
    <>
      <div className="mass_message_popup-head">
        Массовая отправка сообщений доступна в платной версии
      </div>
      <div className="mass_message_popup-body">{failureMessage}</div>
      <div className="mass_message_popup-footer">
        <button className="button btn-3" onClick={handleClose}>
          Закрыть
        </button>
      </div>
    </>
  );
};

export default MassMessageFailure;
