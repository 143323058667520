import { memo } from "react";
import loadingIMG from "../../Assets/images/loading.gif";
import "./loading.scss";

const Loading = () => {
  return (
    <div className="loading">
      <img src={loadingIMG} alt="" />
    </div>
  );
};

export default memo(Loading);
