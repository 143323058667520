import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RemoveFilter } from "../../Core/appRequests/appRequests";
import { setPopupName } from "../../Redux/actions/popupActions";

const RemoveFilterConfirmation = () => {
  const dispatch = useDispatch();
  const id = useSelector((state) => state.popupData.removedFilterId);

  return (
    <div className="confirmation-popup-wrapper">
      <div className="confirmation-popup-content">
        Вы точно хотите удалить фильтр?{" "}
      </div>
      <div className="confirmation-popup-footer">
        <button
          className="reject default-button"
          type="button"
          onClick={() => dispatch(setPopupName())}>
          нет
        </button>
        <button
          className="confirm default-button"
          type="button"
          onClick={() => {
            RemoveFilter(id);
            dispatch(setPopupName());
          }}>
          Да
        </button>
      </div>
    </div>
  );
};

export default RemoveFilterConfirmation;
