import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Search } from "../../Core/appRequests/appRequests";

const sortTypes = {
  match: "совпадению",
  latest_message: "дате сообщения",
  date_update: "дате обновления",
};

const Sorting = ({  loading }) => {
  const [sortBy, setSortBy] = useState("match");
  const selectedFiltersData = useSelector((state) => state.selectedFiltersData);
  const filterID = selectedFiltersData?.filter?.id;

  const handleChangeSortType = useCallback(
    async (key) => {
      await Search({
        filter_id: filterID,
        page: 1,
        sort_by: key,
      });
      setSortBy(key);
    },
    [ filterID]
  );

  return (
    <div className="right-side-sorting">
      <span className="right-side-sorting-title">Сортировка по:</span>
      {Object.keys(sortTypes).map((key, index) => (
        <p
          key={key}
          className={classNames({
            active: key === sortBy,
            disabled: loading,
          })}
          onClick={() => handleChangeSortType(key)}>
          {sortTypes[key]}
          {index !== 2 && <span>|</span>}
        </p>
      ))}
    </div>
  );
};

export default Sorting;
