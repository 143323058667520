export function setShowLoading(data) {
  return {
    type: "SHOW_LOADING",
    payload: data,
  };
}

export function setMessageLoading(payload) {
  return {
    type: "MESSAGE_LOADING",
    payload,
  };
}
