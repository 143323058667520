import { useDispatch } from "react-redux";
import Close from "../../Assets/icons/Close";
import { setPopupName } from "../../Redux/actions/popupActions";
import "./popup.scss";

const Popup = ({ children, show, hideCloseIcon, className, onClose }) => {
  const dispatch = useDispatch();

  return show ? (
    <div className={`pop-up ${className}`}>
      <div className="pop-up-body">
        {!hideCloseIcon ? (
          <span
            className="pop-up-body-close"
            onClick={() => {
              dispatch(setPopupName());
              typeof onClose === "function" && onClose();
            }}>
            <Close />
          </span>
        ) : null}
        {children}
      </div>
    </div>
  ) : null;
};

export default Popup;
