import classNames from "classnames";
import React from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMessagePopupData,
  setMessagePopupList,
} from "../../../Redux/actions/popupActions";
import MassMessageFailure from "../../popup/massMessageFailure";
import MassMessageSuccess from "../../popup/massMessageSuccess";
import Popup from "../../popup/popup";
import SendMassMessagePopup from "../../popup/sendMassMessagePopup";
import ShowMessagesPopup from "../../popup/showMessagesPopup";
import RemoveFilterConfirmation from "../../select/RemoveFilterConfirmation";
import Skills from "../../skills/skills";

const PopupWrapper = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.mainData);

  const popupActionName = useSelector(
    (state) => state.popupData?.popupActionName
  );

  const renderPopupContent = useMemo(() => {
    document.body.style.overflow = popupActionName ? "hidden" : "unset";

    if (popupActionName) {
      switch (popupActionName) {
        case "SKILL_ACTION":
          return <Skills data={data} />;
        case "REMOVE_FILTER":
          return <RemoveFilterConfirmation data={data} />;
        case "MESSAGES_LIST":
          return <ShowMessagesPopup />;
        case "MESSAGE_NOT_AUTH":
          return <div>Требуется авторизация для отправки сообщения</div>;
        case "SEND_MESSAGE":
          return <SendMassMessagePopup />;
        case "MASS_MESSAGE_SUCCESS":
          return <MassMessageSuccess />;
        case "MASS_MESSAGE_FAILURE":
          return <MassMessageFailure />;
        default:
          return null;
      }
    }
  }, [popupActionName, data]);

  const isMassMessagePopup = [
    "SEND_MESSAGE",
    "MASS_MESSAGE_SUCCESS",
    "MASS_MESSAGE_FAILURE",
  ].includes(popupActionName);

  return (
    <Popup
      show={popupActionName}
      className={classNames({
        "send-message-popup": popupActionName === "MESSAGE_NOT_AUTH",
        "messages-list-popup": popupActionName === "MESSAGES_LIST",
        "confirmation-popup": popupActionName === "REMOVE_FILTER",
        mass_message_popup: isMassMessagePopup,
      })}
      hideCloseIcon={popupActionName === "SKILL_ACTION"}
      onClose={() => {
        if (popupActionName === "MESSAGES_LIST") {
          dispatch(setMessagePopupList(null));
          dispatch(setMessagePopupData(null));
        }
      }}>
      {renderPopupContent}
    </Popup>
  );
};

export default PopupWrapper;
